.tw-badge {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 15px;
  padding: 1px 7px 0;
  height: min-content;
  user-select: text;
}
.tw-badge.light {
  background: #eeeeee;
  color: gray;
}
.tw-badge.ultralight {
  background: #fff;
  color: gray;
  border: 1px solid gray;
}
.tw-badge.dark {
  background: gray;
  color: white;
}

@layer tailwind-base {
  @tailwind base;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2J1aWxkcy9lbnRlcnByaXNlc29mdHdhcmUvdGFnLWFwcC13ZWIvc3JjL2Fzc2V0cy9zY3NzL3RhaWx3aW5kL3RhaWx3aW5kLWJhc2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTs7O0FBSUo7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi50dy1iYWRnZSB7XG4gIGRpc3BsYXk6IC13ZWJraXQtYm94O1xuICBkaXNwbGF5OiAtbW96LWJveDtcbiAgZGlzcGxheTogZmxleDtcbiAgLXdlYmtpdC1saW5lLWNsYW1wOiAxO1xuICAtd2Via2l0LWJveC1vcmllbnQ6IHZlcnRpY2FsO1xuICBvdmVyZmxvdzogaGlkZGVuO1xuICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpcztcbiAgYm9yZGVyLXJhZGl1czogMTVweDtcbiAgcGFkZGluZzogMXB4IDdweCAwO1xuICBoZWlnaHQ6IG1pbi1jb250ZW50O1xuICB1c2VyLXNlbGVjdDogdGV4dDtcblxuICAmLmxpZ2h0IHtcbiAgICBiYWNrZ3JvdW5kOiAjZWVlZWVlO1xuICAgIGNvbG9yOiBncmF5O1xuICB9XG4gICYudWx0cmFsaWdodCB7XG4gICAgYmFja2dyb3VuZDogI2ZmZjtcbiAgICBjb2xvcjogZ3JheTtcbiAgICBib3JkZXI6IDFweCBzb2xpZCBncmF5O1xuICB9XG4gICYuZGFyayB7XG4gICAgYmFja2dyb3VuZDogZ3JheTtcbiAgICBjb2xvcjogd2hpdGU7XG4gIH1cbn1cblxuQGxheWVyIHRhaWx3aW5kLWJhc2Uge1xuICBAdGFpbHdpbmQgYmFzZTtcbn0iXX0= */